<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="create()"
          ><CIcon name="cil-plus" /> Create New Account</CButton
        >
        <br />
        <br />
        <CAlert v-if="message" :color="alertType" fade> {{ message }}</CAlert>
        <CDataTable
          :items="items"
          :fields="fields"
          items-per-page-select
          :items-per-page="50"
          hover
          pagination
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.active)">
                {{ item.active == true ? "Active" : "Inactive" }}
              </CBadge>
            </td>
          </template>
          <template #firstName="{ item }">
            <td>
              <strong>{{ item.firstName }}</strong>
            </td>
          </template>
          <template #lastName="{ item }">
            <td>
              <strong>{{ item.lastName }}</strong>
            </td>
          </template>
          <template #email="{ item }">
            <td>
              <strong>{{ item.email }}</strong>
            </td>
          </template>
          <template #mobileNumber="{ item }">
            <td>
              <strong>{{ item.mobileNumber }}</strong>
            </td>
          </template>
          <template #EDIT="{ item }">
            <td>
              <CButton color="primary" @click="editAccount(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td>
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  account = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CModal
          v-model="account.id"
          title="Are you sure to delete selected account?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="6">
              <CInput label="First name" v-model="account.firstName" disabled />
            </CCol>
            <CCol col="6">
              <CInput label="Last Name" v-model="account.lastName" disabled />
            </CCol>
            <CCol col="6">
              <CInput label="Email" v-model="account.email" disabled />
            </CCol>
            <CCol col="6">
              <CInput
                label="Mobile Number"
                v-model="account.mobileNumber"
                disabled
              />
            </CCol>
            <CCol col="6">
              <div><label>Picture</label></div>
              <img
                :src="account.picture"
                class="img-thumbnail"
                style="max-height: 200px"
              />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "Accounts",
  data: () => {
    return {
      // use uuuid
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      items: [],
      account: [],
      warningModal: false,
      fields: [
        "status",
        "firstName",
        "lastName",
        "email",
        "mobileNumber",
        "EDIT",
        "DELETE",
      ],
      // Alert
      alertType: "danger",
      message: null,
    };
  },
  computed: {},
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.account.id);
      }
    },
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },
    accountLink(id) {
      return `accounts/${id.toString()}`;
    },
    editLink(id) {
      return `accounts/create/${id.toString()}`;
    },
    editAccount(id) {
      const editLink = this.editLink(id);
      this.$router.push({ path: editLink });
    },
    delete(id) {
      let self = this;
      axios
        .delete(this.$apiAdress + "/v1/Account/" + id)

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted account.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: "accounts/create/" + this.emptyGuid });
    },

    getList() {
      let self = this;
      axios.get(this.$apiAdress + "/v1/Account")
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
